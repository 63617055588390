import React, { createContext, useContext, useState, useMemo, useCallback } from "react";
import styles from "./styles.module.scss";
import loading from "@/asset/loading.png";

const LoadingContext = createContext();

const Loading = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  // === Updated Start: Fix: Rule#S6481(Line#14) - 20240710 - By PennYao ===
  // 使用useCallback来缓存onLoading函数
  const onLoading = useCallback((params) => {
    setIsLoading(params);
  }, []); // 依赖数组为空，意味着这个函数在组件的整个生命周期内不会改变
  // 使用useMemo来缓存value对象
  const value = useMemo(() => ({ onLoading }), [onLoading]);
  // === Updated End: Fix: Rule#S6481(Line#14) - 20240710 - By PennYao ===

  return (
    <LoadingContext.Provider value={value}>
      <>
        {isLoading && (
          <div className={styles.loading}>
            <img src={loading} alt="loading" />
          </div>
        )}
        {props.children}
      </>
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const { onLoading } = useContext(LoadingContext);
  return onLoading;
};

export default Loading;
