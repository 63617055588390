import React, { createContext, useContext, useEffect, useState, useMemo } from "react";

// 创建 Context
const MyContext = createContext();

// 自定义 Hook，用于在组件中访问 Context
export const useMyContext = () => useContext(MyContext);

// 导出 Context
export default MyContext;

// 创建 Provider
export const MyProvider = ({ children }) => {
  const [isLogon, setIsLogon] = useState(false);
  const [token, setToken] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    try {
      const haUserData = localStorage.getItem("haUserData");
      if (haUserData) {
        const { token, username, date } = JSON.parse(haUserData);
        const now = new Date();
        const diff = Math.abs(now - new Date(date));
        if (diff < 1000 * 60 * 5) { // 登录有效期内
          setToken(token);
          setUsername(username);
          setIsLogon(true);
        } else {
          onLogoff(); // 登录过期，自动登出
        }
      }
    } catch (error) {
      console.log(error);
      onLogoff(); // 登录过期，自动登出
    }
  }, []);

  const onLogon = async (token, username) => {
    const haUserData = { token, username, date: new Date() };
    localStorage.setItem("haUserData", JSON.stringify(haUserData));
    setUsername(username);
    setToken(token);
    setIsLogon(true);
  };

  const onLogoff = () => {
    localStorage.removeItem("haUserData");
    setIsLogon(false);
    setToken("");
    setUsername("");
  };

  // === Updated Start: Fix: Rule#S6481(Line#66) - 20240710 - By PennYao ===
  const value = useMemo(() => ({
    username,
    isLogon,
    onLogon,
    onLogoff,
    token,
  }), [username, isLogon, token]);
  // === Updated End: Fix: Rule#S6481(Line#66) - 20240710 - By PennYao ===

  return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
};
