import React from "react";
import { MyProvider } from "@/context/MyContext";
import Pages from "./page";
import Loading from "./component/Loading";

function setRootFontSize() {
  const viewportWidth =
    document.documentElement.clientWidth || document.body.clientWidth; // 宽度
  if (viewportWidth < 1440) {
    document.documentElement.style.fontSize = "16px";
    return;
  }
  const baseFontSize = 16; // 基准字体大小
  const scaleFactor = viewportWidth / 1440; // 基准宽度为 1440px，可根据实际情况调整
  const rootFontSize = baseFontSize * scaleFactor;
  document.documentElement.style.fontSize = rootFontSize + "px";
}
// // 页面加载时设置根元素字体大小
window.addEventListener("load", setRootFontSize);
// // // 窗口大小变化时重新设置根元素字体大小
window.addEventListener("resize", setRootFontSize);

const App = () => {
  return (
    <Loading>
      <MyProvider>
        <Pages />
      </MyProvider>
    </Loading>
  );
};

export default App;
