import React from "react";
import LogonPage from "@/page/LogonPage";
import SearchPage from "@/page/SearchPage";
import { useMyContext } from "@/context/MyContext";
import styles from "./styles.module.scss";

const Pages = () => {
  const { isLogon } = useMyContext();
  return (
    <div className={styles.container}>
      {isLogon ? <SearchPage /> : <LogonPage />}
    </div>
  );
};

export default Pages;
