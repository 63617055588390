import axios from "axios";

// === Updated Start：Fix: Rule#S125(Line#5) - 20240710 - By PennYao === 
export const LOCAL_URL = "http://localhost:3000/api";
export const PROD_URL = "https://d3218iahohwb7.cloudfront.net/api"; // DEV: http://smsc.free.idcfengye.com | http://smsc2024.free.idcfengye.com | http://192.168.29.41/api | PROD: port: 80, pls contact administrator. eg. http://x.x.x.x/api 
// === Updated End：Fix: Rule#S125(Line#5) - 20240710 - By PennYao === 

export let BASE_URL = PROD_URL;

if (process.env.NODE_ENV === "development") {
  BASE_URL = LOCAL_URL;
}

export const makeGetRequest = async (url, headers = {}) => {
  try {
    const response = await axios.get(url, { ...headers });
    return response.data;
  } catch (e) {
    console.log("request error:", e);
    throw e;
  }
};

export const makePostRequest = async (url, params, headers) => {
  try {
    const response = await axios.post(
      url,
      {
        ...params,
      },
      {
        ...headers,
      }
    );
    return response.data;
  } catch (e) {
    console.log("request error:", e);
    throw e;
  }
};

export const getLogin = (params) => {
  return makePostRequest(BASE_URL + "/user/login", params, {});
};

export const getHaData = (params) => {
  const token = params.token;
  return makePostRequest(BASE_URL + "/conversation/pageList", params.data, {
    headers: {
      token,
    },
  });
};

export const updateScore = (params) => {
  const token = params.token;
  return makePostRequest(
    BASE_URL + "/conversation/updateScore",
    params.data,
    {
      headers: {
        token,
      },
    }
  );
};

export const updateRemark = (params) => {
  const token = params.token;
  return makePostRequest(
    BASE_URL + "/conversation/updateAdditionalResultById",
    params.data,
    {
      headers: {
        token,
      },
    }
  );
};

export const updateOtherRemark = (params) => {
  const token = params.token;
  return makePostRequest(
    BASE_URL + "/conversation/updateOtherRemarks",
    params.data,
    {
      headers: {
        token,
      },
    }
  );
};

export const getVoice = (url, headers) => {
  return makeGetRequest(
    BASE_URL + "/common/download/resource?resource=" + url,
    headers
  );
};
