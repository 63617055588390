import React from "react";
import "./antd.css";
import {Modal, Select} from "antd";
import cn from "classnames";

const MySelect = (props) => {
  const score = props?.data?.score;
  let defaultText = "" + score;

  const questionNo = props?.data?.questionNo;
  const assessmentNo = props?.data?.assessmentNo;
  const remarkResult = props?.data?.remarkResult;
  // === Added Start: Fix Bug#8(PPT#34) - 20240625 - By PennYao ===
  const id = props?.data?.id;
  // === Added End: Fix Bug#8(PPT#34) - 20240625 - By PennYao ===
  const isRemark = props.isRemark;
  const onUpdate = props.onUpdate;
  if (isRemark) {
    defaultText = remarkResult;
  }
  const { Option } = Select;
  const [lastValue, setLastValue] = React.useState(defaultText);

  const confirm = (value) => {
    Modal.confirm({
      title: "Confirm",
      content: "Confirm to save the changes?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: async () => {
        // === Updated Start: Fix Bug#8(PPT#34) - 20240625 - By PennYao ===
        // const res = await onUpdate(value, questionNo, assessmentNo);
        const res = await onUpdate(value, id, questionNo, assessmentNo);
        // === Updated End: Fix Bug#8(PPT#34) - 20240625 - By PennYao ===
        if (res) {
          setLastValue(value);
        }
      },
      maskClosable: true,
    });
  };

  const handleChange = async (value) => {
    confirm(value);
    return false;
  };

  return (
    // === Updated Start: Fix Bug#10(PPT#37) - 20240626 - By PennYao ===
    // <div className="mySelect">
    <div className={cn("mySelect", isRemark ? "remark" : "")}>
    {/* === Updated End: Fix Bug#10(PPT#37) - 20240626 - By PennYao === */}
      <Select
        style={{ width: "100%", padding: "4px 0" }}
        dropdownStyle={{ textAlign: "center" }}
        suffixIcon={null}
        defaultValue={defaultText}
        value={lastValue}
        onChange={handleChange}
      >
        {isRemark ? (
          <>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </>
        ) : (
          <>
            <Option value="0">0</Option>
            <Option value="1">1</Option>
          </>
        )}
      </Select>
    </div>
  );
};

export default MySelect;
