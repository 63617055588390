import React, { useState, useRef, useEffect } from "react";
import play from "@/asset/play.png";
import stop from "@/asset/stop.png";
import "./styles.css";
import { message } from "antd";

const MyAudio = ({ onGetAudio }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);
  const [audioUrl, setAudioUrl] = useState("");
  const audioRef = useRef(new Audio());
  const intervalRef = useRef();

  const { duration } = audioRef.current;

  const refCUrrent = audioRef.current;

  useEffect(() => {
    return () => {
      refCUrrent.pause();
      clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScrub = (value) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
        setTrackProgress(0);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [200]);
  };

  const onScrubEnd = () => {
    startTimer();
  };

  const onStop = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };
  const onPlay = async () => {
    try {
      if (trackProgress !== 0 && audioUrl) {
        audioRef.current.play();
        setIsPlaying(true);
        return;
      }
      const source = await onGetAudio();
      if (source) {
        audioRef.current.src = source;
        audioRef.current.play();
        setAudioUrl(source);
        startTimer();
        setIsPlaying(true);
      }
    } catch (e) {
      // === Updated Start - 20240621 - By PennYao ===
      // message.error("download fail", 3);
      message.error("The file is corrupted and cannot be opened", 3);
      // === Updated End ===
    }
  };
  return (
    <div className="myAudio">
      <div className="audioControl">
        {isPlaying ? (
          <img onClick={onStop} src={stop} alt="stop" />
        ) : (
          <img onClick={onPlay} src={play} alt="play" />
        )}
      </div>
      <input
        type="range"
        value={trackProgress}
        step="1"
        min="0"
        disabled={!audioUrl}
        max={duration ? duration : `${duration}`}
        className="progress"
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
      />
    </div>
  );
};

export default MyAudio;
