import { Input } from "antd";
import cn from "classnames";
import arrow from "@/asset/arrow.png";

// 渲染页面的分页组件
const MyPageInput = ({ myClass, pagesInfo, toPage, setToPage, onToPage, onSearch }) => {
    return (
        <div className={cn("pageBar", myClass)}>
        <div className="totalItem">Total {pagesInfo.total} items</div>
        <div className="pageBtnGroup">
            <div
            onClick={() => {
                pagesInfo?.hasPreviousPage && onSearch(pagesInfo.pageNum - 1)
            }}
            className={cn(
                "leftArrow",
                !pagesInfo?.hasPreviousPage && "disabled"
            )}
            >
            <img src={arrow} alt="arrow" />
            </div>
            <div>{pagesInfo.pageNum}</div>
            <div
            onClick={() => {
                pagesInfo?.hasNextPage && onSearch(pagesInfo.pageNum + 1)
            }}
            className={cn("rightArrow", !pagesInfo?.hasNextPage && "disabled")}
            >
            <img src={arrow} alt="arrow" />
            </div>
        </div>
        <div className="inputBox">
            <div>Go to</div>
            <div className="inputWrap">
            <Input
                value={toPage}
                onChange={(e) => setToPage(e.target.value.replace(/\D/g, ""))}
                onPressEnter={onToPage}
                className="myInput"
            />
            </div>
        </div>
        </div>
    );
};

export default MyPageInput;